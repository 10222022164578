<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">
            <router-link :to="{name: 'AccountExports'}" class="subtitle-1 routerLink font-weight-medium">Exportar</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>Facturas
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 pb-0">
          <v-row class="px-4">
            <v-col cols="12" sm="10" md="9">
              <span class="body-2 grey-500--text font-weight-medium">Filtrar registros</span>
              <v-query-builder :flat="true" :query-map="rules" filter-text="Previsualizar" model="documents" />
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col>
              <span class="body-2 grey-500--text font-weight-medium">Previsualización</span>
            </v-col>
          </v-row>
          <v-divider />
          <documents-list :show-pagination="false" />
          <v-divider />
          <v-row class="px-4">
            <v-col>
              <span class="body-2 grey-500--text font-weight-medium">{{count}} registros</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="createExport" :loading="$store.state.accounts.createLoader" color="blue-500">Exportar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import { documentsFilters } from '@/collections/queryBuilderFilters'

import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  components: {
    VQueryBuilder,
    DocumentsList
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    isPreview: false,
    rules: documentsFilters
  }),
  computed: mapState({
    count: state => state.documents.documentsCount,
    documentsList: state => state.documents.documentsList
  }),
  methods: {
    getList () {
      this.$store.dispatch('documents/LIST', {
        resource: 'documents',
        query: Object.assign({}, this.$route.query, {
          status__in: ['finalized', 'paid', 'cancelled'].join(','),
          page_size: 10
        })
      })
    },
    str2bytes (str) {
      var bytes = new Uint8Array(str.length)
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i)
      }
      return bytes
    },
    createExport () {
      this.$store.dispatch('accounts/LIST', {
        resource: 'exports/documents',
        query: Object.assign({}, this.$route.query, {
          status__in: ['finalized', 'paid', 'cancelled'].join(',')
        }),
        loader: true,
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        let blob = await new Blob([response.data], {type: "application/zip"})
        saveAs(blob, 'documents.zip')
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
    }
  }
}
</script>