const documentsFilters = [
  {
    type: 'text',
    id: 'customer__taxpayer__name',
    label: 'Cliente',
    value: null,
    category: 'a',
    operators: [''],
    countries: ['PE', 'CL']
  },
  {
    type: 'text',
    id: 'customer__taxpayer__tax_id',
    label: 'RUT',
    value: null,
    category: 'a',
    operators: [],
    countries: ['PE', 'CL']
  },
  {
    type: 'date',
    id: 'date',
    label: 'Fecha',
    value: null,
    value2: null,
    category: 'b',
    operator: null,
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'date',
    id: 'due_date',
    label: 'Fecha de vencimiento',
    value: null,
    value2: null,
    category: 'b',
    operator: null,
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: true,
    id: 'document_type__in',
    value: null,
    label: 'Tipo de documento',
    choices: [],
    category: 'c',
    countries: ['PE', 'CL']
  },
  {
    type: 'numeric',
    id: 'prefix',
    label: 'Serie',
    value: null,
    category: 'c',
    countries: ['PE']
  },
  {
    type: 'numeric',
    id: 'number',
    label: 'Número de folio',
    value: null,
    value2: null,
    category: 'c',
    operator: null,
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    id: 'collection_method',
    label: 'Forma de pago',
    valueType: 'number',
    value: null,
    choices: [
      {label: 'Contado', value: 1},
      {label: 'Crédito', value: 2},
      {label: 'Sin costo', value: 3}
    ],
    category: 'c',
    countries: ['PE', 'CL']
  },
  // {
  //   type: 'radio',
  //   id: 'credit_note__isnull',
  //   label: 'Tiene nota de crédito',
  //   value: null,
  //   choices: [
  //     {label: 'Si', value: 'False'},
  //     {label: 'No', value: 'True'}
  //   ],
  //   category: 'c',
  //   countries: ['PE', 'CL']
  // },
  {
    type: 'numeric',
    id: 'total',
    label: 'Total',
    value: null,
    value2: null,
    category: 'c',
    operator: null,
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: false,
    id: 'branch_id',
    value: null,
    label: 'Tienda',
    choices: [],
    category: 'c',
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: false,
    id: 'status__code__in',
    value: null,
    label: 'Estado SII',
    choices: [],
    category: 'd',
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: false,
    id: 'customer_response__code__in',
    value: null,
    label: 'Estado comercial',
    choices: [],
    category: 'd',
    countries: ['PE', 'CL']
  },
  {
    type: 'text',
    id: 'status__detail',
    label: 'Detalle del estado',
    value: null,
    category: 'd',
    operators: ['contiene'],
    countries: ['PE', 'CL']
  }
]
export {
  documentsFilters
}